import React, { useState, useEffect } from 'react';
import { Box, Snackbar } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '../../../assets/images/editIcon.svg';
import DeleteIcon from '../../../assets/images/DeleteIcon.svg';
// import addNew from '../../assets/images/addNew.svg';
import AddAudio from './AddAudio.js';
import DeleteModal from './DeleteModal';
import { useNavigate } from 'react-router-dom';
import {baseUrl} from '../../../config/apiConfig'
import axios from "axios";
import '../GlobalSetting.scss'

const FloorManagement = () => {
    const [open, setOpen] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [message, setMessage] = useState('Delete Successfully.');
    const [rows, setRows] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [reloadAudioList, setReloadAudioList] = useState(false);
    const [editData, setEditData] = useState(null);
    const [deviceIdToDelete, setDeviceIdToDelete] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null); // To track the selected row
    const navigate = useNavigate();

    const columns = [
        {
            field: 'audioName',
            headerName: 'Audio Name',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
        },
        {
            field: 'audiofile',
            headerName: 'Audio File',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            renderCell: (params) => (
                <div>
                    <a href={params.value} className="blurPrintLink">
                        Download
                    </a>
                </div>
            ),
        },
        {
            field: 'select',
            headerName: 'Select',
            flex: 0.5,
            sortable: false,
            disableColumnMenu: true,
            cellClassName: 'centeredCell',
            renderCell: (params) => (
                <input
                    type="checkbox"
                    checked={params.row.default || params.row.id === selectedRowId}
                    onChange={() => handleCheckboxChange(params.row.id)}
                />
            ),
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => (
                <div>
                    <img
                        src={DeleteIcon}
                        alt="delete"
                        style={{ width: '32px', height: '32px', cursor: 'pointer', marginLeft: '10px' }}
                        onClick={() => {
                            setOpenDeleteModal(true);
                            setDeviceIdToDelete(params.row.id);
                        }}
                    />
                </div>
            ),
        },
    ];

    const handleCheckboxChange = (id) => {
        // Toggle selection
        setSelectedRowId((prevSelectedId) => (prevSelectedId === id ? null : id));
    };

    const processedRows = Array.isArray(rows)
        ? rows.map((row, index) => ({
              id: row.id,
              audioName: row.title || 'N/A',
              audiofile: row.filepath || 'N/A',
          }))
        : [];

    const fetchData = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `${baseUrl}/getAllAlarmMedia`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setRows(response.data);
                const defaultRow = response.data.find((row) => row.default === true);
                if (defaultRow) {
                    setSelectedRowId(defaultRow.id);
                }
            }
        } catch (error) {
            console.error('Error making request:', error);
        }
    };

    
    useEffect(() => {
        const initialize = async () => {
            await fetchData();
        };
        initialize();
    }, []);

    useEffect(() => {
        if (reloadAudioList) {
            fetchData();
        }
    }, [reloadAudioList]);
 
    
    useEffect(() => {
        const updateSelectedAudio = async () => {
            if (selectedRowId === null) return; // Skip if no row is selected
        
            try {
                let token = JSON.parse(localStorage.getItem('token'));
                const url = `https://lockdownexperts.biz/identity-api/api/v1/setMediaAlarm/defaultActive/${selectedRowId}`; // Send the ID in the URL
        
                const response = await axios.put(
                    url, 
                    { // No body needed if no data to send in the request body
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
        
                if (response.status === 200) {
                    console.log('Selected audio updated successfully:', response.data);
                    fetchData(); // Assuming this is a function to refresh the data
                } else {
                    console.error('Failed to update selected audio:', response.data);
                }
            } catch (error) {
                console.error('Error updating selected audio:', error);
            }
        };
    
        updateSelectedAudio();
    }, [selectedRowId]); // Dependency array with selectedRowId
    

    const handleDelete = async (id) => {
        let token = JSON.parse(localStorage.getItem('token'));
        const url = `https://lockdownexperts.biz/identity-api/api/v1/deleteAlarmMediaById/${id}`;
        try {
            const response = await axios.delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                fetchData();
                setOpenToast(true);
            } else {
                console.error('Failed to delete device:', response.data);
            }
        } catch (error) {
            console.error('Error deleting device:', error);
        }
    };

    const handleToastClose = () => {
        setOpenToast(false);
        setMessage('');
    };

    return (
        <>
            <div className="userMangement_wrapper">
                <div className="AddUser">
                    <button className="addDevice" onClick={() => setOpen(true)}>
                        Add Audio
                    </button>
                </div>
                <div className="inner_Wrapper">
                    <div className="floorMangementTable_wrapper">
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={processedRows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
            </div>
            <AddAudio
                open={open}
                setOpen={setOpen}
                setReloadAudioList={setReloadAudioList}
            />
            <DeleteModal
                setOpenDeleteModal={setOpenDeleteModal}
                openDeleteModal={openDeleteModal}
                handleDelete={() => handleDelete(deviceIdToDelete)}
            />
            <Snackbar
                open={openToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                message={message}
                onClose={handleToastClose}
                className={'success'}
            />
        </>
    );
};

export default FloorManagement;
