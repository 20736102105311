import React, { useState, useEffect } from 'react';
import { Box, Snackbar } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '../../assets/images/editIcon.svg';
import DeleteIcon from '../../assets/images/DeleteIcon.svg';
import addNew from '../../assets/images/addNew.svg';
import AddEditFloor from './AddEditFloor.js'
import DeleteModal from './DeleteModal'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './style.scss'

const FloorManagement = () => {
    const [open, setOpen] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [message, setMessage] = useState('Delete Successfully.');
    const [rows, setRows] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [actionButton, setActionButton] = useState("")
    const [editData, setEditData] = useState(null);
    const [deviceIdToDelete, setDeviceIdToDelete] = useState(null);
    const navigate = useNavigate();
    const columns = [
        {
            field: 'floor',
            headerName: 'Floor',
            flex: 1,
            sortable: true,
            disableColumnMenu: false
        },
        {
            field: 'floorPlan',
            headerName: 'Floor Plan',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            renderCell: (params) => (
                <div>
                    <a href={params.value} className="blurPrintLink"
                    >
                        Blueprint
                    </a>
                </div>
            ),
        },
        {
            field: 'ConfigurationAdministrator',
            headerName: 'Configuration Administrator',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            renderCell: (params) => (
                <div>
                    {Array.isArray(params.value) && params.value.length > 0 ? (
                        params.value.map((val, index) => (
                            <span key={index}>{val}</span>
                        ))
                    ) : (
                            <span>N/A</span>
                        )}
                </div>
            ),
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => (
                <div>
                    <img
                        src={EditIcon}
                        alt="edit"
                        style={{ width: '28px', height: '28px', cursor: 'pointer' }}
                        onClick={() => handleEdit(params.row)}
                    />
                    <img
                        src={DeleteIcon}
                        alt="delete"
                        style={{ width: '32px', height: '32px', cursor: 'pointer', marginLeft: '10px' }}
                        onClick={() => { setOpenDeleteModal(true); setDeviceIdToDelete(params.row.id) }}
                    />

                    <img
                        src={addNew}
                        alt="addnew"
                        style={{ width: '24px', height: '24px', cursor: 'pointer', marginLeft: '10px' }}
                        onClick={() => goToAddDevices(params.row.id)}
                    />
                </div>
            ),
        }
    ]
    const handleEdit = (deviceData) => {
        setEditData(deviceData);
        setActionButton("edit");
        setOpen(true);
    };
    // const selectedFloorHandler = (e) =>{
    //     setSelectedFloor(e.target.value)
    // }

    const processedRows = Array.isArray(rows) ? rows.map((row, index) => ({
        id: row.id,
        floor: row.floorName || 'N/A', // Assuming each row needs an `id` field
        floorPlan: row.floorImageUrl || 'N/A',
        // Ensure floorUsers is an array and map over it to get the names
        ConfigurationAdministrator: Array.isArray(row.floorUsers) && row.floorUsers.length > 0
            ? row.floorUsers.map(user => user.name)  // Joins multiple names if needed
            : 'N/A'
    })) : [];
    // Listing APi 
    const fetchData = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `https://demo.lockdownexperts.biz/api/v1/floor/floorList`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setRows(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    useEffect(() => {
        // Call the async function
        fetchData();
    }, [actionButton]);

    const handleDelete = async (id) => {
        let token = JSON.parse(localStorage.getItem("token"));
        const url = `https://demo.lockdownexperts.biz/api/v1/floor/${id}`;
        try {
            const response = await axios.delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.status === 200) {
                fetchData();
                setOpenToast(true)
            } else {
                console.error("Failed to delete device:", response.data);
                // Handle any errors here, such as displaying a message to the user
            }
        } catch (error) {
            console.error("Error deleting device:", error);
        }
    };

    const handleToastClose = () => {
        setOpenToast(false)
        setMessage("")
    };
    const goToAddDevices = (id) => {
        navigate(`/floorManagement/assignDevices/${id}`);
    };
    return (
        <>
            <div className="userMangement_wrapper floor_managment_wrapper">
                <div
                    className="main_heading"
                >
                    <h1>Floor Management</h1>
                </div>
                <div className="AddUser">
                    <button className="addDevice" onClick={() => { setOpen(true); setActionButton("add") }}>Add Floor</button>
                </div>
                <div className="inner_Wrapper">
                    <div className="floorMangementTable_wrapper">
                        <Box sx={{ height: 'auto', width: '100%' }}>
                            <DataGrid
                                rows={processedRows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
            </div>
            <AddEditFloor
                open={open}
                setOpen={setOpen}
                setActionButton={setActionButton}
                actionButton={actionButton}
                editData={editData}
            />
            <DeleteModal
                setOpenDeleteModal={setOpenDeleteModal}
                openDeleteModal={openDeleteModal}
                handleDelete={() => handleDelete(deviceIdToDelete)}
            />
            <Snackbar
                open={openToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                message={message}
                onClose={handleToastClose}
                className={"success"}
            />
        </>

    )
}

export default FloorManagement
