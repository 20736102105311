import React, { useEffect, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { Box, NativeSelect, FormControl, InputLabel } from "@mui/material";
import './HistoryComp.scss';
import axios from "axios";
import { Container, Row, Col } from 'react-bootstrap';

const HistoryComp = () => {
    const [videoSources, setVideoSources] = useState([]); // Store multiple video sources
    const [floorDetails, setFloorDetails] = useState([]);

    // Floor Listing API 
    const fetchData = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `https://demo.lockdownexperts.biz/api/v1/floor/floorList`;
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setFloorDetails(response.data);
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };

    // Fetch video sources
    const fetchVideoSources = async () => {
        const response = await new Promise((resolve) => {
            setTimeout(() => {
                resolve([
                    {
                        id: '1',
                        name: 'CCTV-CAM01 | Pre School Classroom',
                        src: 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8',
                        type: 'application/x-mpegURL',
                    },
                    {
                        id: '2',
                        name: 'CCTV-CAM01 | Pre School Classroom',
                        src: 'https://test-streams.mux.dev/test_001/stream.m3u8',
                        type: 'application/x-mpegURL',
                    },
                    {
                        id: '3',
                        name: 'CCTV-CAM02 | Pre School Classroom',
                        src: 'https://test-streams.mux.dev/elephantsdream/playlist.m3u8',
                        type: 'application/x-mpegURL',
                    },
                    {
                        id: '4',
                        name: 'CCTV-CAM02 | Pre School Classroom',
                        src: 'https://dash.akamaized.net/akamai/bbb_30fps/bbb_30fps.m3u8',
                        type: 'application/x-mpegURL',
                    },
                ]);
            }, 1000);
        });

        setVideoSources(response);
    };

    // Initialize all video.js players
    const initializePlayers = () => {
        videoSources.forEach((source) => {
            const videoPlayerElement = document.getElementById(`video-player-${source.id}`);
            if (videoPlayerElement) {
                const player = videojs(videoPlayerElement, {
                    autoplay: true,
                    controls: true,
                    controlBar: {
                        pipToggle: false,
                        fullscreenToggle: true,
                        volumePanel: false,
                        playToggle: false,
                        progressControl: false,
                        remainingTimeDisplay: false,
                        currentTimeDisplay: false,
                        timeDivider: false,
                        playbackRateMenuButton: false,
                    },
                    preload: 'auto',
                    sources: [
                        {
                            src: source.src,
                            type: 'application/x-mpegURL',
                        },
                    ],
                });

                player.on('error', (error) => {
                    console.error(`Video.js player error for player ${source.id}:`, error);
                });
            }
        });
    };

    // Cleanup players when component unmounts or video sources change
    useEffect(() => {
        fetchData(); // Fetch floor details on mount
        fetchVideoSources(); // Fetch video sources on mount
    }, []);

    useEffect(() => {
        if (videoSources.length > 0) {
            // Initialize video.js players once sources are available
            initializePlayers();

            // Cleanup video.js players when the component is unmounted or sources change
            return () => {
                videoSources.forEach((source) => {
                    const player = videojs.getPlayer(`video-player-${source.id}`);
                    if (player) {
                        player.dispose();
                    }
                });
            };
        }
    }, [videoSources]);

    return (
        <div className="deviceMangement_wrapper">
            <div className="main_heading">
                <h1>Device Management</h1>
            </div>
            <div className="AddFloor camera_feed_floor">
                <Box sx={{ minWidth: 340 }} className="floor-dropdown">
                    <FormControl fullWidth className="innerFields ">
                        <InputLabel variant="standard" shrink={false}>
                            Floor
                        </InputLabel>
                        <NativeSelect>
                            <option value="" disabled>Select an option</option>
                            {Array.isArray(floorDetails) && floorDetails.map((floor) => (
                                <option key={floor.id} value={floor.id}>
                                    {floor.floorName}
                                </option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                </Box>
            </div>
            <div className="video-container">
                <Container>
                    <Row>
                        {videoSources.map((source) => (
                            <Col xl={6} lg={6} md={12} sm={12} key={source.id}>
                                <div className="videoPlaer_wrapper">
                                    <video
                                        id={`video-player-${source.id}`}
                                        className="video-player video-js vjs-default-skin"
                                        height="235"
                                        controls
                                        autoPlay
                                        muted
                                    >
                                        Your browser does not support the video tag.
                                    </video>
                                    <p className="camera_details">{source.name}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default HistoryComp;
