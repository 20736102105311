import React, { useState, useEffect, useRef, act } from "react";
import "./style.scss";
import axios from "axios";
import {
  Box,
  NativeSelect,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import sirenSound from "../../assets/sounds/siren.mp3";
import alertIcon from "../../assets/images/alertIcon.svg";
import construction from "../../assets/images/construction.svg";
import constructionWhite from "../../assets/images/constructionWhite.svg";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { updateFloorData, floorMapRef } from "../../Actions/FloormapAction";
import { useScreenshot } from "use-react-screenshot";
import { newDevicePosition, controlButtons } from "./customJson";
import { styled } from "@mui/material/styles";
import mqtt from "mqtt";
const url = "https://demo.lockdownexperts.biz/api/v1";

// const url = "http://localhost:8080";
const TooltipContent = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
});
const ImageTooltipContent = () => (
  <TooltipContent>
    <img src={constructionWhite} style={{ display: "block" }} />
    <div>Need Repair</div>
  </TooltipContent>
);

const FloorMap = ({ printView, activeFloor, newref }) => {
  const [ids, setIds] = useState(0);
  const [rows, setRows] = useState([]);
  const [activeStatus, setActiveStatus] = useState([]);
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [floorDetails, setFloorDetails] = useState({});
  const [alarmEngaged, setAlarmEngaged] = useState(false);
  const dispatch = useDispatch();
  const [prevFloorDataLength, setPrevFloorDataLength] = useState(0);
  const [image, takeScreenshot] = useScreenshot();
  const [takingScreenshot, setTakingScreenshot] = useState(false);
  const [activeFloorId, setActiveFloorId] = useState(null);
  const [triggerBtn, setTriggerBtn] = useState(null);
  const [showFLoorName, setShowFLoorName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mesg, setMesg] = useState("Waiting on message...");
  const mqttClient = useRef(null);
  const [floorData, setFloorData] = useState([]);
  const [currentFloor, setCurrentFloor] = useState(1);

  // const client = mqtt.connect('ws://test.mosquitto.org:1883');
  // var options = {
  //   protocol: 'websockets',
  //   clientId: 'string123' //This can be any unique id
  // }

  // var client = mqtt.connect('tcp://test.mosquitto.org:1883', options)

  // client.subscribe('MQTTinReact')
  // client.on('connect', function () {
  //   console.log('Connected to MQTT broker')
  // })
  // client.on('message', function (topic, message) {
  //   console.log('Received message:', message, 'on topic:', topic)
  // })

  var options = {
    protocol: "websockets",
    clientId: "string123", //This can be any unique id
  };

  const {
    FloormapReducer: {
      activeFloorRef: activeFloorRef,
      activeFloorData: activeFloorData,
      activeFloorPRintData: activeFloorPRintData,
    },
  } = useSelector((state) => state);
  const ref = useRef(activeFloorRef);
  const fetchData = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("token"));
      const url = `https://demo.lockdownexperts.biz/api/v1/floor/floorList`;
      const response = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setRows(response.data);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };
  useEffect(() => {
    // Call the async function
    fetchData();
  }, []);

  const activeFloorDetails = (id) => {
    setActiveTab(id);
    setCurrentFloor(id);
  };

  useEffect(() => {
    setActiveTab(activeFloorData);
    dispatch(floorMapRef(ref));
  }, [activeFloorData]);

  const setIdsfunc = (id) => {
    setIds(id);
    if (id == 11) {
      setActiveStatus(activeStatus === 3 ? "activated" : "");
    }
  };

  const isDeviceVisible = (state) => {
    return true;
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          "https://demo.lockdownexperts.biz/api/v1/device/getAllLocationDevices",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // const response = await axios.get(`${url}/GetDevices`);
        const devices = response.data;
        // setData(devices);
        // dispatch(updateFloorData(devices));
        const matcheddevices = devices.find(
          (devices) => devices.id === activeTab
        );
        if (matcheddevices) {
          setData(matcheddevices);
          setFloorData(matcheddevices);
          // dispatch(updateFloorData(matcheddevices));
          setIsLoading(false);
          mqttClient.current = mqtt.connect(
            "wss://mqtt.lockdownexperts.biz:8084",
            {
              username: "mqtt_admin",
              password: "z9kwJiUbbieNtODi",
            }
          );
          window.mqttConnected = true;
          mqttClient.current.on("connect", function () {
            mqttClient.current.subscribe("niagara/#");
          });
          mqttClient.current.on("message", function (topic, message) {
            const decodedMessage = new TextDecoder("utf-8").decode(message);
            const deviceId = parseInt(topic.split("/").pop());
            // if (deviceId) {
            //   devices.flatMap((x) => x.devices).filter((x) => x.deviceId == deviceId)[0].state = JSON.parse(
            //       decodedMessage
            //     )
            //       ? 3
            //       : 1;
            //   dispatch(updateFloorData(devices));
            // }
            // New code

            if (deviceId) {
              // Log the devices array to verify the structure

              // Flatten the devices array and try to find the matching deviceId
              const deviceList = devices.flatMap((x) => x.devices || []); // Ensure x.devices is an array
              // console.log('Flattened Device List:', deviceList);

              const device = deviceList.find((x) => x.deviceId == deviceId); // Use find instead of filter to avoid the [0] issue

              if (device) {
                // Log the device found for further inspection

                // Set the state only if device exists
                device.state = JSON.parse(decodedMessage) ? 3 : 1;

                // Dispatch the updated devices object

                dispatch(updateFloorData(devices));
              } else {
                // Log an error if no device was found
                console.error(
                  `Device with ID ${deviceId} not found in the list`
                );
              }
            } else {
              console.error("Invalid deviceId:", deviceId);
            }
            // New code
            if (topic.includes("pushbtn") && JSON.parse(decodedMessage)) {
              const newAudioElement = document.getElementById("myAudio");
              if (newAudioElement) {
                newAudioElement.src = sirenSound;
                // Initialize a counter to track how many times the audio has played
                // let playCount = 0;
                // const maxPlays = 1;
                // // Function to handle audio ended event
                // newAudioElement.addEventListener("ended", function () {
                //   playCount += 1; // Increment play count each time audio ends

                //   if (playCount < maxPlays) {
                //     // If play count is less than maxPlays, play again
                //     newAudioElement.play();
                //   }
                // });

                // Start playing the audio
                newAudioElement.play();
              }
            }
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [activeTab]);
  console.log(isLoading, "isloadinggggggg");
  const resetRequest = async () => {
    const topics = [
      "niagara/device/floor1/pushbtn/11/sub",
      "niagara/device/floor1/pushbtn/14/sub",
      "niagara/device/floor2/pushbtn/24/sub",
      "niagara/device/floor2/pushbtn/27/sub",
    ];
    const message = "false";
    if (mqttClient.current && mqttClient.current.connected) {
      topics.forEach((topic) => {
        mqttClient.current.publish(topic, message);
      });
      const newAudioElement = document.getElementById("myAudio");
      if (newAudioElement) {
        newAudioElement.pause();
      }
    } else {
      console.error("MQTT client is not connected.");
    }
  };
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    if (triggerBtn !== null && image) {
      setTimeout(async function () {
        try {
          const formData = new FormData();
          let triggers = floorData?.devices
            ?.flatMap((x) => x?.devices || []) // Ensure `devices` exists and provide a fallback empty array
            .filter((x) => x?.state === 3) // Safeguard `x` and check `state`
            .map((x) => x.deviceId); // Map `deviceId`

          if (triggers?.length > 0) {
            formData.append("status", 3);
            formData.append("deviceIds", triggers);
          }

          // Remove the base64 prefix if present
          const base64Image = image ? image.split(",")[1] || image : null;

          // Validate the base64 string
          if (!/^[A-Za-z0-9+/=]+$/.test(base64Image)) {
            throw new Error("Invalid base64 string");
          }

          // Convert base64 to Blob
          const blob = new Blob(
            [Uint8Array.from(atob(base64Image), (c) => c.charCodeAt(0))],
            { type: "image/png" }
          );

          formData.append("image", blob, "floorMap.png"); // Append image with filename
          await axios.post(
            "https://demo.lockdownexperts.biz/api/v1/device/initiateLockdownDevice",
            formData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          console.error("Error making POST request:", error);
        }
      }, 1000);
      let activePushBtn = document.getElementById(`trigger-${triggerBtn}`);
      activePushBtn && activePushBtn.classList.add("active");
    }
  }, [triggerBtn]);

  useEffect(() => {
    if (activeTab) {
      if (Array.isArray(rows)) {
        const matchedFloor = rows.find((floor) => floor.id === activeTab);
        setFloorDetails(matchedFloor || rows[0]);
      }
    } else {
      setFloorDetails(rows[0]);
    }
  }, [activeTab, rows]);
  return floorData ? (
    <div className={`floorMap_wrapper`}>
      <div
        style={{ display: printView ? "none" : "flex" }}
        className="main_heading"
      >
        <h1>Floors</h1>
        <div className="blinkText">
          {(Array.isArray(floorData?.devices) ? floorData.devices : [])
            .filter((device) => device.state === 3)
            .map((device) => device.deviceId).length > 0 ? (
            <p>Lockdown</p>
          ) : (
            ""
          )}
        </div>
        <div>
          <p></p>
        </div>
      </div>
      <div className="innerWrapper">
        <div
          style={{ display: printView ? "none" : "inline-flex" }}
          className="floorTab"
        >
          <ul>
            {rows &&
              Array.isArray(rows) &&
              rows.map((floor, index) => {
                return (
                  <li key={index}>
                    <a
                      className={floor.id === activeTab ? "active " : ""}
                      onClick={() => activeFloorDetails(floor?.id)}
                    >
                      {floor.floorName}

                      {Array.isArray(floorData?.devices) &&
                      floorData.devices
                        .filter(
                          (device) =>
                            (device.deviceId === 11 ||
                              device.deviceId === 14) &&
                            device.state === 3
                        )
                        .map((device) => device.deviceId).length > 0 ? (
                        <span>
                          <img
                            src={alertIcon}
                            className="alerticon"
                            alt="img"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      {(Array.isArray(floorData?.devices)
                        ? floorData.devices
                        : []
                      )
                        .filter((device) => device.state === 2)
                        .map((device) => device.deviceId).length > 0 ? (
                        <span className="constructionImg">
                          <img
                            src={construction}
                            className="construction"
                            alt="img"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </a>
                  </li>
                );
              })}
            {/* Remove list item */}
            {/* <li>
              <a
                className={activeTab === 1 ? "active " : ""}
                onClick={() => setActiveTab(1)}
              >
                Floor 1
                {floorData[0]?.devices
                  .filter(
                    (device) =>
                      (device.deviceId == 11 || device.deviceId == 14) &&
                      device.state === 3
                  )
                  .map((device) => device.deviceId).length > 0 ? (
                  <span>
                    <img src={alertIcon} className="alerticon" alt="img" />
                  </span>
                ) : (
                  ""
                )}
                {floorData[0]?.devices
                  .filter((device) => device.state === 2)
                  .map((device) => device.deviceId).length > 0 ? (
                  <span className="constructionImg">
                    <img
                      src={construction}
                      className="construction"
                      alt="img"
                    />
                  </span>
                ) : (
                  ""
                )}
              </a>
            </li>
            <li>
              <a
                className={activeTab === 2 ? "active " : ""}
                onClick={() => setActiveTab(2)}
              >
                Floor 2
                {floorData[1]?.devices
                  .filter(
                    (device) =>
                      (device.deviceId == 24 || device.deviceId == 27) &&
                      device.state === 3
                  )
                  .map((device) => device.deviceId).length > 0 ? (
                  <span>
                    <img src={alertIcon} className="alerticon" alt="img" />
                  </span>
                ) : (
                  ""
                )}
                {floorData[1]?.devices
                  .filter((device) => device.state === 2)
                  .map((device) => device.deviceId).length > 0 ? (
                  <span className="constructionImg">
                    <img
                      src={construction}
                      className="construction"
                      alt="img"
                    />
                  </span>
                ) : (
                  ""
                )}
              </a>
            </li> */}
          </ul>
          <button className={`resetBtn`} onClick={() => resetRequest()}>
            Clear Alarms
          </button>
        </div>
        <div className="bottom_wrapper">
          <div className="map_wrp_inout">
            <div
              id="printFloorMap"
              className={
                // ${activeTab == 1 ? "mapOne" : "mapTwo"}
                `floorOne  ${printView ? "forPrintView" : ""}
              ${
                (Array.isArray(floorData?.devices)
                  ? floorData.devices
                  : []
                ).filter((device) => device.state === 3).length > 0
                  ? "specialClass"
                  : ""
              }
            `
              }
              ref={ref}
            >
              {!isLoading ? (
                <img src={floorDetails?.floorImageUrl} alt="img" />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              {printView && <h2>Floor {activeTab}: Blueprint</h2>}
              <div className={"showFloor"}>{floorDetails?.floorName}</div>
              {activeFloorPRintData && <div>{floorDetails?.floorName}</div>}
              {!isLoading &&
                floorData &&
                (Array.isArray(floorData?.devices)
                  ? floorData.devices
                  : []
                ).map((item, index) => {
                  return (
                    <Tooltip
                      title={
                        item.deviceType == "1" && item.state === 2 ? (
                          <ImageTooltipContent />
                        ) : item.deviceType == "4" && item.state === 2 ? (
                          <ImageTooltipContent />
                        ) : item.deviceType == "1" && item.state === 3 ? (
                          "Capturing"
                        ) : item.deviceType == "4" && item.state === 3 ? (
                          "Activated"
                        ) : (
                          ""
                        )
                      }
                      arrow
                      placement="top"
                      open={
                        !printView &&
                        (item.deviceType == "1" ||
                          (item.deviceType == "4" &&
                            (item.state === 2 || item.state === 3)))
                      }
                      classes={{
                        tooltip: "customTooltip",
                        arrow: "customArrow",
                      }}
                    >
                      <button
                        x-data={"print"}
                        id={`trigger-${item.deviceId}`}
                        className={`signBtn  ${
                          item.deviceType == "1"
                            ? "camerBtn"
                            : item.deviceType == "2"
                            ? "commonLight"
                            : item.deviceType == "3"
                            ? "audioIcon"
                            : item.deviceType == "4"
                            ? "pushBtn"
                            : ""
                        } ${
                          item.deviceType == "1" && item.state === 2
                            ? "cameraBroken"
                            : item.deviceType == "4" && item.state === 2
                            ? "pushBroken"
                            : ""
                        } five ${ids === item.deviceId ? "active" : ""} ${
                          item.state === 3
                            ? printView &&
                              (item.deviceType == "1" || item.deviceType == "4")
                              ? "active "
                              : "activated disnone"
                            : "disnone"
                        }`}
                        style={{
                          position: "absolute",
                          // transform: `rotate(${item?.rotate}deg)`,
                          left: `${item?.cssXCoordinate}%`,
                          top: `${item?.cssYCoordinate}%`,
                          display: `
                        ${
                          isDeviceVisible(
                            (Array.isArray(floorData?.devices)
                              ? floorData.devices
                              : []
                            ).filter((x) => x.deviceId === item.deviceId)[0]
                              ?.state
                          )
                            ? "block"
                            : "none"
                        }
                          `,
                        }}
                      >
                        {item.deviceType == "3" && item.deviceType == "3" && (
                          <span></span>
                        )}
                      </button>
                    </Tooltip>
                  );
                })}
            </div>
          </div>
          {!printView ? (
            <Footer floorMapRef={ref} currentFloor={currentFloor} />
          ) : (
            ""
          )}

          {/* To Place image for screenshot */}
          {/* <div id="placeholder"></div> */}
          {/* <img
            width={`100px`}
            height={`100px`}
            src={image}
            alt={"Screenshot"}
          /> */}
        </div>
      </div>
      {!printView && <audio id="myAudio"></audio>}
    </div>
  ) : (
    ""
  );
};

export default FloorMap;
